import React from 'react';
import _ from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';
import { Container } from 'constate';

import BackgroundImage from 'gatsby-background-image';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { authEffects } from '../app/effects/auth';

import { PageTitle } from '../components/title';
import Layout from '../components/layout';
import { GridRow } from '../components/widgets/grid';

const Trial = ({register}) => {

  const { allOnboarding, desktop, ipad, mobile } = useStaticQuery(query);
  const { nodes: [{ trial }]} = allOnboarding;

  const sources = [
    mobile.childImageSharp.fluid,
    {
      ...ipad.childImageSharp.fluid,
      media: `(min-width: 700px)`,
    },
    {
      ...desktop.childImageSharp.fluid,
      media: `(min-width: 1224px)`,
    }
  ];

  return (
    <BackgroundImage
      className="graph-white"
      style={{
        width: '100%',
        backgroundPosition: '50%',
        backgroundRepeat: 'repeat-y',
        backgroundSize: 'cover',
      }}
      fluid={sources}
      backgroundColor='#F9F9F9'
    >
      <div className="pb4">

        <PageTitle title="Try SwitchSmith" />

        <GridRow>

          <div className="row">

            <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
              <ul className="fa-ul">
                {trial.map((term, idx) =>
                  <li key={idx}>
                    <FontAwesomeIcon icon={['fad', 'check']} listItem/>
                    {term}
                  </li>
                )}
              </ul>
            </div>

          </div>

        </GridRow>

        {/* <GridRow>
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 center-xs">
            <div onClick={() => { register() }}>
              <a className="f5 ttu tracked grow hover-yellow no-underline br-pill ph3 pv2 mb2 dib white bg-mid-gray">
                Start Free
              </a>
            </div>
          </div>
        </GridRow> */}
      </div>

    </BackgroundImage>
  );
}

const TrialPage = () =>
  <Layout>
    <Container
    context='primary'
    effects={authEffects}>
      {(props) => (
        <Trial {...props} />
      )}
    </Container>
  </Layout>

export default TrialPage;

export const query = graphql`
  query {
    allOnboarding {
      nodes {
        trial
      }
    }
    desktop: file(relativePath: {regex: "/175521459/"}) {
      childImageSharp {
        fluid(quality: 35, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    ipad: file(relativePath: {regex: "/651808214/"}) {
      childImageSharp {
        fluid(quality: 35, maxWidth: 1224) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mobile: file(relativePath: {regex: "/1144431648/"}) {
      childImageSharp {
        fluid(quality: 35, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`